/* You can add global styles to this file, and also import other style files */


/*Menu Bar Starts*/
/* .navbar-nav .nav-item .nav-link .badge {
    position: absolute;
    margin-left: 0.75rem;
    top: 0.3rem;
    font-weight: 400;
    font-size: 0.5rem;
} */

button.calendar, button.calendar:active {
    width: 2.75rem;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
  }
  .card-body-icon{
    z-index:999 !important;
    /* margin-right: 22px; */
}
.card-body{
  border-bottom: 1px solid rgba(0, 0, 0, 2.15) !important;
  padding: 0.50rem !important;
}
:root {
  --body-back-ground-color: #18373f;
  --back-ground-color: #055160;
  --back-ground-color-alpha: #18525f85;
  --back-ground-faded-color: #2796af;
  --back-ground-table-odd-color: #08687e; 
  --back-ground-table-even-color: #2f7e91;
  --text-color:#02feff;
  --text-active-color:#02feff;
  --back-ground-bubble:#4d765f;
  --back-ground-bubble-text:yellow;
}