body{
    background-color:var(--body-back-ground-color, white);
    color:white;
}
.bg-dark {
    /* background-color: #ea5e5e !important; */
    background-color: var(--back-ground-color, #ea5e5e) !important;
}

.sidebar {
    /* background-color: #ea5e5e!important; */
    background-color: var(--back-ground-color, #ea5e5e) !important;
}

.sidebar .nav-item .nav-link span {
    color:var(--text-color, white) !important;
    font-weight: bold;
 }
 .sidebar .nav-item .nav-link {
    color:var(--text-color, white) !important;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--back-ground-color,  #f90d0d);
    border-color: white;
}
.navbar-dark .navbar-nav .nav-link {
    color:var(--text-color, white) !important;
    
}
.btn-primary {
    /* color: #fff; */
    color: var(--text-color, #fff);
    /* background-color: #f90d0d; */
    background-color: var(--back-ground-color,  #f90d0d);
    border-color: white;
    border-radius: 28px;
}
.btn-primary:hover {
    /* color: #fff; */
    color: var(--text-color, #fff);
    /* background-color: #f90d0d; */
    background-color: var(--back-ground-color,  #f90d0d);
    border-color: whitesmoke;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    /* background-color: #ea5e5e; */
    background-color: var(--back-ground-color, #ea5e5e);
    border-radius: 0.25rem;
}
.breadcrumb-item.active {
    color: white;
    font-weight: bold;
}
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: var(--text-color, white);;
    content: "/";
}
ol.breadcrumb a {
    /* color: white; */
    color: var(--text-color, white);
}
.btn-outline-secondary {
    color: white;
    border-color: white;
    background-color:var(--back-ground-faded-color, white) ;
}
.btn-outline-secondary:hover {
    
    background-color:white ;
}
.btn-link {
    color: white;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--back-ground-faded-color, #df0808a6);
}
.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color:var(--back-ground-faded-color, #df0808a6) ;
    border-color: white;
    box-shadow: 0 0 0 0.2rem var(--back-ground-faded-color, #df0808a6);
}
.scroll-to-top,.scroll-to-top-hidemenu{
    background: var(--back-ground-color-alpha, #df0808a6);
    color: #fff !important;
}
.scroll-to-top:hover,.scroll-to-top-hidemenu:hover {
    background: var(--back-ground-faded-color, #df0808a6);
}

.badge-danger{
    color: var(--back-ground-bubble-text, white);;;
    background-color: var(--back-ground-bubble, #e50505);;
    font-weight: bolder !important;
    /* font-size: 11px !important; */
}
/* Zebra striping */
table tr:nth-of-type(odd) {
    background-color:var(--back-ground-table-odd-color, #df0808a6);
    /* color: var(--text-color, white); */
}
table tr:nth-of-type(even) {
    background-color:var(--back-ground-table-even-color, #db7171);
    /* color: var(--text-color, white); */
}
table th {
    background-color:var(--back-ground-color, #df0808a6);
    color: var(--text-color, white);
    font-weight: bold;
}
table td {
    color:white;
}
i.fa.fa-times {
    color: var(--text-color, white) !important;
}
/*ag grid override*/
.ag-theme-balham .ag-row-odd {
    background-color:var(--back-ground-table-odd-color, #df0808a6);
    color: white;
    font-weight: bold;
}
.ag-theme-balham .ag-row-even {
    background-color:var(--back-ground-table-even-color, #db7171);
    color: white;
    font-weight: bold;
}
.ag-theme-balham {
    background-color: var( --back-ground-faded-color,#1c778b );
    color: var(--text-color, white) !important;
}
.ag-theme-balham .ag-header {
    background-color:var(--back-ground-color, #df0808a6);
    color: var(--text-color, white) !important;
}

/*legend*/
legend.section-legend-border {
    width: inherit;
    padding: 0 10px;
    border-bottom: none;
    /* background: #ff7e7e; */
    background-color:var(--back-ground-faded-color, #ff7e7e);
    border-radius: 23px;
    color: white;
  }
  fieldset.section-legend-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    box-shadow: 0px 0px 0px 0px #000;
    width: auto;
    /* background-color: #df0808a6; */
     background-color:var(--back-ground-color, #df0808a6);
    color: var(--text-color, white);
    font-weight: bold;
    border-radius: 32px;
  }
  
  legend.section-legend-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
  }
  .sub-section-legend {
    /* background-color: #ea5e5e !important; */
    background-color:var(--back-ground-color, #df0808a6) !important;
}
/*Product Details Page*/
  .section-income-heading-div {
    background-color:var(--back-ground-color, #df0808a6); 
    
}
/*habit section*/
.habit-log-row{
    background-color:var(--back-ground-color, #df0808a6) ;
    color: white;
}
.btn{
    color: white !important;
}
.span-menu{
    background-color:var(--back-ground-color, #df0808a6) !important ;
}
.activity-type-item span {
    background-color:var(--back-ground-color, #df0808a6);
}

span.active {
    background-color:var(--back-ground-faded-color, #df0808a6) !important;
}

.round-div{
    border-radius: 32px !important;
}
/*Product*/
.activeButton {
    background-color:var(--back-ground-faded-color, #df0808a6);
    border-color: white;
}
/*Interview Question*/
#topic-list span, #subject-list span{background-color:var(--back-ground-color, #df0808a6);
    border-color: white;
    color: white;
}


